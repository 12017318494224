import { combineReducers } from "redux";
import authReducers from "./authReducers.js";
import alertReducers from "./alertReducer";
import userReducer from "./userReducer";
import contentManagement from "./cmsReducer.js";
import categoryReducer from "./categoryReducer";
import businessReducer from "./businessReducer";
import galleryReducer from "./galleryReducer";
import settingReducer from "./settingReducer";
import contactReducer from "./contactReducer";
import languageReducer from "./languageReducer.js";
import labelReducer from "./labelReducer";
import dashboardReducer from "./dashboardReducer";
import makeReducers from "./makeReducers";
import modelReducers from "./modelReducer";
import notificationReducer from "./notificationReducer.js";
export default combineReducers({
  auth: authReducers,
  alert: alertReducers,
  users: userReducer,
  cmsDetails: contentManagement,
  category: categoryReducer,
  business: businessReducer,
  gallery: galleryReducer,
  setting: settingReducer,
  contact: contactReducer,
  languages: languageReducer,
  labels: labelReducer,
  dashboard: dashboardReducer,
  makebrand: makeReducers,
  modelbrand: modelReducers,
  notification: notificationReducer,
});
