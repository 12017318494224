import { GET_DASHBOARD_DATA } from "../actions/types";

const initialState = {
  result: {
    totalUsers: 0,
    availableProducts: 0,
    soldProducts: 0,
    soldProductsAmount: 0,
  },
  loading: true,
};

export default function dashboard(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DASHBOARD_DATA:
      return { ...state, loading: false, result: payload };
    default:
      return state;
  }
}
