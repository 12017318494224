import { GET_ALL_BUSINESS } from '../actions/types'

const initialState = {
  businessList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },

  loading: true,
}

const category = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_BUSINESS:
      return {
        ...state,
        businessList: payload,
        loading: false,
      }

    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}
export default category
