import { GET_MODEL_lIST } from "../actions/types";

const initialState = {
    ModelList: {
    docs: [],
    totalDocs: 0,
    limit: 10,
    page: 1,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
};

const modelReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MODEL_lIST:
      return { ...state, loading: false, ModelList: payload };

    case "SET_LOADING":
      return { ...state, loading: true };

    default:
      return state;
  }
};
export default modelReducers;
