import { GET_CONTACT } from '../actions/types'

const initialState = {
  contactList: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 0,
    totalPages: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  loading: true,
}

const contact = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONTACT:
      return {
        ...state,
        contactList: payload,
        loading: false,
      }

    default:
      return state
  }
}

export default contact
